import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Row, Container, Col } from "react-bootstrap"

import YAMLdata from "./home-about.yaml"

const HomeAbout = () => {
  return (
    <section className="about_area pad_btm">
      <Container className="p0" fluid>
        <article className="about_inner p_x">
          <Row className="no-gutters">
            <Col
              xs={{ order: 2, span: 12 }}
              sm={{ order: 2, span: 12 }}
              lg={{ order: 1, span: 6 }}
              className="about_content about_bg"
            >
              <div className="about_content_ph">
                <h2>{YAMLdata.title}</h2>
                <p className="black-dash">
                  {YAMLdata.section1} <br />
                  <br />
                  {YAMLdata.section2}{" "}
                  <Link to="/who-we-are" className="genric-btn circle arrow">
                    more...
                  </Link>
                </p>
              </div>
            </Col>
            <Col
              xs={{ order: 1, span: 12 }}
              sm={{ order: 1, span: 12 }}
              lg={{ order: 2, span: 6 }}
              className="about_image"
            >
              <StaticImage
                src="../images/about/about-image.jpg"
                alt="About Us"
              />
            </Col>
          </Row>
        </article>
      </Container>
    </section>
  )
}
export default HomeAbout
