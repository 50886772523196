import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Row, Container, Col } from "react-bootstrap"

import YAMLdata from "./home-hero.yaml"

const HomeHero = () => {
  return (
    <section className="hero_area pad_btm">
      <Container className="p0" fluid>
        <Row className="no-gutters">
          <Col className="hero_title" lg={6}>
            <h2>{YAMLdata.title}</h2>
          </Col>
          <Col lg={6}></Col>
        </Row>
        <Row className="no-gutters">
          <Col className="hero_projects" lg={6}>
            {/* Project Category Starts */}
            <Row className="no-gutters">
              <Col sm={4}>
                <Link to="/projects/bars-&amp;-lounges" className="cta-link">
                  <div className="project_cta proj_fnb">
                    <div className="prod_cta_img">
                      <StaticImage
                        className="hero_cat_img"
                        src="../images/hero/projects-fnb.jpg"
                        alt="F&amp;B Projects"
                      />
                    </div>
                    <div className="prod_cta_title">F&amp;B</div>
                  </div>
                </Link>
              </Col>
              <Col sm={4}>
                <Link to="/projects/hospitality" className="cta-link">
                  <div className="project_cta proj_lei">
                    <div className="prod_cta_img">
                      <StaticImage
                        className="hero_cat_img"
                        src="../images/hero/projects-leisure.jpg"
                        alt="Leisure projects"
                      />
                    </div>
                    <div className="prod_cta_title">Leisure</div>
                  </div>
                </Link>
              </Col>
              <Col sm={4}>
                <Link to="/projects/workspaces" className="cta-link">
                  <div className="project_cta proj_lux">
                    <div className="prod_cta_img">
                      <StaticImage
                        className="hero_cat_img"
                        src="../images/hero/projects-luxury.jpg"
                        alt="Luxury Projects"
                      />
                    </div>
                    <div className="prod_cta_title">Luxury</div>
                  </div>
                </Link>
              </Col>
            </Row>
            {/* Project Category Ends */}
          </Col>
          <Col lg={6} className="no-gutters">
            <div className="hero_content">
              <p className="pink-dash">{YAMLdata.content}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default HomeHero
