import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import HomeHero from "../sections/home-hero"
import HomeAbout from "../sections/home-about"
import ClientsList from "../sections/clients-list"

const IndexPage = () => (
  <Layout>
    <Seo
      title="Home"
      description="WDA is an award-winning architecture and interior design studio with a reputation for creating purposeful spaces with a distinct energy."
    />
    <HomeHero />
    <HomeAbout />
    <ClientsList />
  </Layout>
)

export default IndexPage
